import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SettingsService {
    constructor(httpClient) {
        this._settings = httpClient.get(`api/settings`).toPromise();
    }
    settings() {
        return this._settings;
    }
}
SettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SettingsService_Factory() { return new SettingsService(i0.ɵɵinject(i1.HttpClient)); }, token: SettingsService, providedIn: "root" });
