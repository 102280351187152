import { of } from 'rxjs';
import { ContentType, FeedParser, LogCollector, OutputType } from '../../../../core/src';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FeedService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.defaultOptions = {
            o: OutputType.ATOM,
            c: ContentType.RAW,
            js: false
        };
    }
    fromHTML(html, options, url) {
        const domParser = new DOMParser();
        const htmlDoc = domParser.parseFromString(html, 'text/html');
        const logCollector = new LogCollector(console);
        const feedParser = new FeedParser(htmlDoc, url, options, logCollector);
        const result = {
            logs: logCollector.logs(),
            usesExistingFeed: false,
            options,
            rules: feedParser.getArticleRules(),
            articles: feedParser.getArticles(),
            html
        };
        return of(result);
    }
    applyRule(html, url, rule, options) {
        const logCollector = new LogCollector();
        const htmlDoc = new DOMParser().parseFromString(html, 'text/html');
        const feedParser = new FeedParser(htmlDoc, url, options, logCollector);
        return of(feedParser.getArticlesByRule(rule));
    }
    getDirectFeedUrl(url, options) {
        return `/api/feed?url=${encodeURIComponent(url)}`
            + this.feedUrlFragment('pContext', options)
            + this.feedUrlFragment('js', options)
            + this.feedUrlFragment('pLink', options)
            + this.feedUrlFragment('o', options)
            + this.feedUrlFragment('c', options)
            + this.feedUrlFragment('xq', options)
            + this.feedUrlFragment('x', options);
    }
    fromUrl(url, options) {
        const parserUrl = `/api/feed/live?url=${encodeURIComponent(url)}`
            + this.feedUrlFragment('pContext', options)
            + this.feedUrlFragment('js', options)
            + this.feedUrlFragment('pLink', options)
            + this.feedUrlFragment('o', options)
            + this.feedUrlFragment('c', options)
            + this.feedUrlFragment('xq', options);
        return this.httpClient.get(parserUrl);
    }
    feedUrlFragment(id, options) {
        function prop(obj, key) {
            return obj[key];
        }
        if (prop(this.defaultOptions, id) !== prop(options, id)) {
            return `&${id}=${encodeURIComponent(prop(options, id))}`;
        }
        return '';
    }
    getDynamicContent(url) {
        return this.httpClient.get(`/api/dynamic?url=${url}`, { responseType: 'text' });
    }
}
FeedService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeedService_Factory() { return new FeedService(i0.ɵɵinject(i1.HttpClient)); }, token: FeedService, providedIn: "root" });
