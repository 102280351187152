import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { ContentType, FeedParser, OutputType } from '../../../../../core/src';
import { build } from '../../../environments/build';
import * as URI from 'urijs';
export class PlaygroundComponent {
    constructor(httpClient, sanitizer, router, settings, activatedRoute, changeDetectorRef, feedService) {
        this.httpClient = httpClient;
        this.sanitizer = sanitizer;
        this.router = router;
        this.settings = settings;
        this.activatedRoute = activatedRoute;
        this.changeDetectorRef = changeDetectorRef;
        this.feedService = feedService;
        this.html = '';
        this.feedData = '';
        this.showViz = 'viz';
        this.hasResults = false;
        this.iframeLoaded = false;
        this.options = {};
        this.isLoading = false;
        this.isGenerated = false;
        this.excludeItemsThatContain = false;
        this.excludeItemsThatContainTexts = 'Newsletter, Advertisement';
        this.hasJsSupport = false;
        this.history = PlaygroundComponent.getHistory();
    }
    static getHistory() {
        return JSON.parse(localStorage.getItem('history') || JSON.stringify([]));
    }
    ngOnInit() {
        this.resetAll();
        this.activatedRoute.queryParams.subscribe(params => {
            if (params.url) {
                this.url = params.url;
                this.parseFromUrlInternal();
            }
        });
        this.settings.settings().then(settings => {
            this.hasJsSupport = settings.jsSupport;
            this.footer = {
                visible: settings.showFooterBanner,
                message: settings.footerMessage
            };
            this.changeDetectorRef.detectChanges();
        });
    }
    applyRule(rule) {
        console.log('apply rule', rule);
        this.currentRule = rule;
        this.options.pContext = rule.contextXPath;
        this.options.pLink = rule.linkXPath;
        this.options.x = rule.extendContext;
        this.feedService.applyRule(this.html, this.url, rule, this.options).subscribe(articles => {
            this.articles = articles;
        });
        this.highlightRule(rule);
        this.changeDetectorRef.detectChanges();
    }
    parseFromUrl() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.isLoading) {
                return;
            }
            if (this.activatedRoute.snapshot.queryParams.url === this.url) {
                this.parseFromUrlInternal();
            }
            else {
                const queryParams = { url: this.url };
                return this.router.navigate([], {
                    relativeTo: this.activatedRoute,
                    queryParams,
                    queryParamsHandling: 'merge',
                });
            }
        });
    }
    getFeedUrl() {
        return this.feedService.getDirectFeedUrl(this.url, this.options);
    }
    getVersions() {
        return build;
    }
    resetAll() {
        this.options = {
            o: OutputType.ATOM,
            c: ContentType.RAW,
            xq: this.excludeItemsThatContainTexts,
            js: false,
            x: 's'
        };
        this.html = '';
        this.hasResults = false;
        this.iframeLoaded = false;
        this.feeds = [];
        this.currentRule = null;
        this.logs = [];
        // this.url = null;
        this.rules = null;
        this.feedData = '';
        if (this.proxyUrl) {
            window.URL.revokeObjectURL(this.proxyUrl);
        }
        this.resetErrors();
        this.changeDetectorRef.detectChanges();
    }
    resetErrors() {
        this.error = null;
    }
    getBuildDate() {
        const date = new Date(parseInt(this.getVersions().date, 10));
        return `${date.getUTCDate()}-${date.getUTCMonth()}-${date.getUTCFullYear()}`;
    }
    parseFromHistoryUrl(url) {
        this.url = url;
        return this.parseFromUrl();
    }
    isCurrentRule(rule) {
        return this.currentRule === rule;
    }
    onIframeLoad() {
        if (this.rules) {
            this.updateScores();
        }
        else {
            this.iframeLoaded = true;
        }
    }
    updateScores() {
        const iframeDocument = this.iframeRef.nativeElement.contentDocument;
        this.rules.forEach(rule => {
            const articles = this.evaluateXPathInIframe(rule.contextXPath, iframeDocument)
                // remove hidden articles
                .filter((elem) => !!(elem.offsetWidth || elem.offsetHeight));
            if (articles.length === 0) {
                rule.score -= 20;
                // rule.hidden = true;
            }
        });
        this.changeDetectorRef.detectChanges();
    }
    parseFromUrlInternal() {
        if (isEmpty(this.url)) {
            this.error = '';
            this.changeDetectorRef.detectChanges();
            return;
        }
        this.resetErrors();
        this.addToHistory(this.url);
        if (!this.url.startsWith('http://') && !this.url.startsWith('https://')) {
            this.url = 'http://' + this.url;
        }
        try {
            // tslint:disable-next-line:no-unused-expression
            new URL(this.url);
        }
        catch (e) {
            this.error = 'Please enter a valid url';
            this.changeDetectorRef.detectChanges();
            return;
        }
        this.isLoading = true;
        this.changeDetectorRef.detectChanges();
        this.updateParserOptions();
        if (this.options.js) {
            this.fromDynamicSource();
        }
        else {
            this.fromStaticSource();
        }
    }
    fromStaticSource() {
        console.log('from static source');
        this.feedService.fromUrl(this.url, this.options)
            .subscribe(this.handleParserResponse(), (error) => {
            this.isLoading = false;
            this.hasResults = false;
            this.error = error.message;
            this.changeDetectorRef.detectChanges();
        });
    }
    handleParserResponse() {
        return (response) => {
            this.hasResults = true;
            this.isLoading = false;
            if (response.message) {
                this.isGenerated = false;
                try {
                    const { html, feeds, logs } = response.data;
                    if (html) {
                        this.feeds = feeds;
                        this.html = html;
                        this.prepareIframe(this.patchHtml(html, this.url));
                    }
                    if (logs) {
                        this.logs = [...logs, response.message];
                    }
                    else {
                        this.error = response.message;
                    }
                }
                catch (e) {
                    this.error = response.message;
                }
                console.error('Proxy replied an error.', response.message);
                // tslint:disable-next-line:max-line-length
                this.error = `Looks like this site does not contain any feed data.`;
            }
            else {
                this.isGenerated = true;
                console.log('Proxy replies an generated feed');
                this.rules = response.rules;
                this.prepareIframe(this.patchHtml(response.html, this.url));
                setTimeout(() => {
                    this.applyRule(this.rules[0]);
                }, 1000);
                this.setCurrentTab(this.showViz);
                this.feeds = response.feeds;
                this.feedData = response.feed;
                const optionsFromParser = response.options;
                this.options.c = optionsFromParser.c;
                this.options.o = optionsFromParser.o;
                // todo mag add fallback option
                this.changeDetectorRef.detectChanges();
            }
        };
    }
    addToHistory(url) {
        let history = this.history.filter(otherUrl => otherUrl !== url);
        history = history.reverse();
        history.push(url);
        history = history.reverse();
        history = history.filter((otherUrl, index) => index < 15);
        this.history = history;
        localStorage.setItem('history', JSON.stringify(history));
    }
    setCurrentTab(tab) {
        this.currentTab = tab;
    }
    assignToIframe(html) {
        this.proxyUrl = window.URL.createObjectURL(new Blob([html], {
            type: 'text/html'
        }));
        this.iframeRef.nativeElement.src = this.proxyUrl;
        this.changeDetectorRef.detectChanges();
    }
    prepareIframe(html) {
        this.assignToIframe(html);
    }
    patchHtml(html, url) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        const base = doc.createElement('base');
        base.setAttribute('href', url);
        doc.getElementsByTagName('head').item(0).appendChild(base);
        Array.from(doc.querySelectorAll('[href]')).forEach(el => {
            try {
                const absoluteUrl = new URI(el.getAttribute('href')).absoluteTo(url);
                el.setAttribute('href', absoluteUrl.toString());
            }
            catch (e) {
                // console.error(e);
            }
        });
        return doc.documentElement.innerHTML;
    }
    highlightRule(rule) {
        const iframeDocument = this.iframeRef.nativeElement.contentDocument;
        const id = 'rss-proxy-style';
        try {
            iframeDocument.getElementById(id).remove();
        }
        catch (e) {
        }
        const styleNode = iframeDocument.createElement('style');
        styleNode.setAttribute('type', 'text/css');
        styleNode.setAttribute('id', id);
        const allMatches = this.evaluateXPathInIframe(rule.contextXPath, iframeDocument);
        const qualifiedAsArticle = (elem) => {
            // todo apply filters
            return FeedParser.qualifiesAsArticle(elem, rule, iframeDocument);
        };
        const matchingIndexes = allMatches
            .map(elem => {
            const index = Array.from(elem.parentElement.children)
                .findIndex(otherElem => otherElem === elem);
            const qualified = qualifiedAsArticle(elem);
            if (qualified) {
                console.log(`Keeping element ${index}`, elem);
            }
            else {
                console.log(`Removing unqualified element ${index}`, elem);
            }
            return { elem, index, qualified };
        })
            .filter(candidate => candidate.qualified)
            .map(candidate => candidate.index);
        const cssSelectorContextPath = 'body>' + FeedParser.getRelativeCssPath(allMatches[0], iframeDocument.body, true);
        console.log(cssSelectorContextPath);
        const code = `${matchingIndexes.map(index => `${cssSelectorContextPath}:nth-child(${index + 1})`).join(', ')} {
            border: 3px dotted red!important;
            margin-bottom: 5px!important;
            display: block;
          }
          `;
        const firstMatch = allMatches[0];
        if (firstMatch) {
            firstMatch.scrollIntoView();
        }
        styleNode.appendChild(iframeDocument.createTextNode(code));
        iframeDocument.head.appendChild(styleNode);
    }
    evaluateXPathInIframe(xPath, context) {
        const iframeDocument = this.iframeRef.nativeElement.contentDocument;
        const xpathResult = iframeDocument.evaluate(xPath, context, null, 5);
        const nodes = [];
        let node = xpathResult.iterateNext();
        while (node) {
            nodes.push(node);
            node = xpathResult.iterateNext();
        }
        return nodes;
    }
    updateParserOptions() {
        if (this.excludeItemsThatContain) {
            this.options.xq = this.excludeItemsThatContainTexts;
        }
        else {
            delete this.options.xq;
        }
        if (this.currentRule) {
            this.options.pContext = this.currentRule.contextXPath;
            this.options.pLink = this.currentRule.linkXPath;
        }
    }
    fromDynamicSource() {
        console.log('from dynamic source');
        const url = this.url;
        this.feedService.getDynamicContent(url).subscribe(html => {
            this.isLoading = false;
            const patchedHtml = this.patchHtml(html, url);
            this.feedService.fromHTML(patchedHtml, this.options, url).subscribe(this.handleParserResponse(), console.error);
            this.prepareIframe(patchedHtml);
        });
    }
}
